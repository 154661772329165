import { useStaticQuery, graphql } from 'gatsby'

export const useFooterData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 1452 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                acf {
                  text_signup
                  email_placeholder
                  first_component_footer
                  first_component_slug
                  second_component_footer
                  second_component_slug
                  third_component_footer
                  third_component_slug
                  four_component_footer
                  four_component_slug
                  five_component_footer
                  five_component_slug
                  six_component_footer
                  six_component_slug
                  seven_component_footer
                  seven_component_slug
                  eight_component_footer
                  eight_component_slug
                  privacy_policy_text
                  privacy_policy_slug
                  cookie_policy_text
                  cookie_policy_slug
                  terms_text
                  terms_slug
                  all_rights_reserved
                  articles {
                    title
                    articles_url {
                      article1 {
                        name
                        url
                      }
                      article2 {
                        name
                        url
                      }
                      article3 {
                        name
                        url
                      }
                      article4 {
                        name
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
