import React, { useState } from 'react'

import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Send from '@material-ui/icons/Send'
import SettingsBackupRestore from '@material-ui/icons/SettingsBackupRestore'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import CheckIcon from '../static/img/icons/check_icon.svg'
import { D, M, DesktopFlex, MobileFlex } from './breakpoints'
import Section from './Section'
import { useFooterData } from '../hooks/use-footer-data'
import WhiteLogo from '../static/img/hubtype_logo_white.svg'

import Container from './Container'

const FooterContainer = styled(Flex)`
  position: relative;
  &:before {
    display: ${props => props.displayBefore || 'block'};
    content: '';
    position: absolute;
    top: -150px;
    height: 100%;
    width: 100%;
    left: 0px;
    right: 0px;
    background-color: #6500cb;
    z-index: -10;
  }
  margin-top: ${props => props.marginTop || '0px'};
`

const FooterCapStyle = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  text-decoration: none !important;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 10px;
  color: white;
`

const Hint = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */
  margin-bottom: 15px;
  margin-top: 30px;
  color: #ffffff;
`

const FooterBottomitems = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px; /* identical to box height */
  text-decoration: none !important;
  margin-right: 10%;
  display: inline-block;
  white-space: nowrap;
  color: #b282ff;
`

const SignUpFeedback = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  max-width: 230px;
  margin-right: 5px;
  line-height: 21px;
  color: #ffffff;
`

const BlogArticles = styled.a`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-decoration: none !important;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 10px;
  color: #cbaaff;
`

const Footer = props => {
  const { whiteLogo, whiteLogoMobile } = useStaticQuery(
    graphql`
      query {
        whiteLogo: file(relativePath: { eq: "whiteLogo.png" }) {
          childImageSharp {
            fixed(width: 100, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        whiteLogoMobile: file(relativePath: { eq: "whiteLogoMobile.png" }) {
          childImageSharp {
            fixed(width: 120, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  let lang = props.pageContext.lang
  const {
    acf: {
      articles,
      text_signup,
      email_placeholder,
      first_component_footer,
      first_component_slug,
      second_component_footer,
      second_component_slug,
      third_component_footer,
      third_component_slug,
      four_component_footer,
      four_component_slug,
      five_component_footer,
      five_component_slug,
      six_component_footer,
      six_component_slug,
      seven_component_footer,
      seven_component_slug,
      eight_component_footer,
      eight_component_slug,
      privacy_policy_text,
      privacy_policy_slug,
      cookie_policy_text,
      cookie_policy_slug,
      terms_text,
      terms_slug,
      all_rights_reserved,
    },
  } = useFooterData(lang)
  const [userData, updateData] = useState({
    comesFrom: 'web',
    email: '',
    lang,
  })
  const [isSend, setIsSend] = useState(false)

  let copys = {
    pricing: lang == 'es_ES' ? 'planes' : 'pricing',
    newsletter:
      lang == 'es_ES' ? 'SUSCRÍBETE A NUESTRA NEWSLETTER' : 'SIGNUP FOR NEWS',
    contact: lang == 'es_ES' ? 'contacta' : 'contact',
    legal: lang == 'es_ES' ? 'asuntos legales' : 'legal',
    privacy: lang == 'es_ES' ? 'Politica de privacidad' : 'Privacy policy',
    cookies: lang == 'es_ES' ? 'Politica de cookies' : 'Cookie policy',
    terms: lang == 'es_ES' ? 'Términos y condiciones' : 'Terms and conditions',
    language: lang == 'es_ES' ? '/es' : lang == 'en_GB' ? '/uk' : '',
  }

  const sendEmail = () => {
    if (typeof window === 'undefined') return
    window.analytics.identify(userData)
    window.analytics.track('Newsletter Signup', userData)
    let {
      email: Email,
      firstName: FirstName,
      lastName: LastName,
      ...custom
    } = userData
    window.Autopilot.run('associate', {
      Email,
      FirstName,
      LastName,
      custom,
    })
    setIsSend(true)
    // updateData({
    //   comesFrom: 'web',
    //   email: '',
    //   lang,
    // })
  }
  const resetSendEmail = event => {
    updateData({
      comesFrom: 'web',
      email: '',
      lang,
    })
    setIsSend(false)
    event.preventDefault()
  }
  return (
    <FooterContainer
      displayBefore={props.displayBefore}
      pt={[30, 90]}
      pb={20}
      css={{
        position: 'relative',
        background: '#6500cb',
      }}
      flexDirection="column"
      alignItems="center"
      justifyContent="stretch"
      pt={[4, 0]}
      marginTop={props.marginTop}
    >
      <Container pt={[30, 80]}>
        <DesktopFlex flexDirection="column">
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection="column">
              <Img fixed={whiteLogo.childImageSharp.fixed} />
              <Hint>{text_signup}</Hint>
              {!isSend && (
                <TextField
                  style={{
                    border: '1px solid #CBAAFF',
                    borderRadius: '4px',
                    maxWidth: 285,
                    height: '48px',
                  }}
                  id="input-with-icon-textfield"
                  variant="outlined"
                  placeholder={email_placeholder}
                  value={userData.email}
                  onChange={props =>
                    updateData({ ...userData, email: props.target.value })
                  }
                  inputProps={{ style: { color: 'white' } }}
                  InputProps={{
                    style: { height: '48px' },
                    endAdornment: (
                      <InputAdornment
                        style={{
                          cursor: 'pointer',
                          color: 'white',
                        }}
                        position="end"
                        onClick={sendEmail}
                      >
                        <Send />
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      sendEmail()
                      ev.preventDefault()
                    }
                  }}
                />
              )}
              {isSend && (
                <Flex flexDirection="row">
                  <CheckIcon style={{ marginRight: '5%' }} />
                  <SignUpFeedback>
                    {userData.email} has been successfully subscribed
                  </SignUpFeedback>
                  <SettingsBackupRestore
                    style={{
                      color: '#CBAAFF',
                      width: '35px',
                      height: '35px',
                      marginRight: '5%',
                    }}
                    onClick={resetSendEmail}
                  />
                </Flex>
              )}
            </Flex>
            <Flex flexDirection="column">
              {Object.values(articles.articles_url).map((article, index) => {
                return (
                  <BlogArticles key={index} href={article.url}>
                    {article.name}
                  </BlogArticles>
                )
              })}
            </Flex>
            <Flex flexDirection="row">
              <Flex flexDirection="column" mr={50}>
                <FooterCapStyle href={first_component_slug}>
                  {first_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={second_component_slug}>
                  {second_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={third_component_slug}>
                  {third_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={four_component_slug}>
                  {four_component_footer}
                </FooterCapStyle>
              </Flex>
              <Flex flexDirection="column">
                <FooterCapStyle href={five_component_slug}>
                  {five_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={six_component_slug}>
                  {six_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={seven_component_slug}>
                  {seven_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={eight_component_slug}>
                  {eight_component_footer}
                </FooterCapStyle>
                <FooterBottomitems
                  style={{
                    marginTop: '30px',
                    position: 'absolute',
                    bottom: 20,
                  }}
                >
                  {all_rights_reserved}
                </FooterBottomitems>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            width={1}
            mt={'60px'}
            justifyContent={'flex-start'}
            flexDirection={'row'}
          >
            {/*
              <FooterBottomitems>Español</FooterBottomitems>*/}
            <Flex
              width={2 / 3}
              justifyContent={'flex-start'}
              flexDirection={'row'}
            >
              <Box>
                <FooterBottomitems href={copys.language + privacy_policy_slug}>
                  {privacy_policy_text}
                </FooterBottomitems>
              </Box>
              <Box ml={4}>
                <FooterBottomitems href={copys.language + cookie_policy_slug}>
                  {cookie_policy_text}
                </FooterBottomitems>
              </Box>
              <Box ml={4}>
                <FooterBottomitems href={copys.language + terms_slug}>
                  {terms_text}
                </FooterBottomitems>
              </Box>
            </Flex>
            {/* <Flex width={1 / 3} ml={8}>
              <FooterBottomitems>© 2019 All rights reserved</FooterBottomitems>
            </Flex> */}
          </Flex>
        </DesktopFlex>
        <MobileFlex>
          <Flex ml={'15px'} mr={'80px'} flexDirection="column">
            <Img fixed={whiteLogoMobile.childImageSharp.fixed} />
            <Hint>{text_signup}</Hint>
            {!isSend && (
              <TextField
                style={{
                  border: '1px solid #CBAAFF',
                  borderRadius: '4px',
                }}
                id="input-with-icon-textfield"
                variant="outlined"
                placeholder={email_placeholder}
                value={userData.email}
                onChange={props =>
                  updateData({ ...userData, email: props.target.value })
                }
                inputProps={{ style: { color: 'white' } }}
                InputProps={{
                  style: { height: '48px' },
                  endAdornment: (
                    <InputAdornment
                      style={{ cursor: 'pointer', color: 'white' }}
                      position="end"
                      onClick={sendEmail}
                    >
                      <Send />
                    </InputAdornment>
                  ),
                }}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    sendEmail()
                    ev.preventDefault()
                  }
                }}
              />
            )}
            {isSend && (
              <Flex flexDirection="row">
                <CheckIcon style={{ marginRight: '5%' }} />
                <SignUpFeedback>
                  {userData.email} has been successfully subscribed
                </SignUpFeedback>
                <SettingsBackupRestore
                  style={{
                    color: '#CBAAFF',
                    width: '35px',
                    height: '35px',
                    marginRight: '3%',
                  }}
                  onClick={resetSendEmail}
                />
              </Flex>
            )}
            <Flex mt={'30px'} mr={20} justifyContent={'space-between'}>
              <Flex flexDirection="column">
                <FooterCapStyle href={first_component_slug}>
                  {first_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={second_component_slug}>
                  {second_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={third_component_slug}>
                  {third_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={four_component_slug}>
                  {four_component_footer}
                </FooterCapStyle>
              </Flex>
              <Flex flexDirection="column" ml={-10}>
                <FooterCapStyle href={five_component_slug}>
                  {five_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={six_component_slug}>
                  {six_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={seven_component_slug}>
                  {seven_component_footer}
                </FooterCapStyle>
                <FooterCapStyle href={eight_component_slug}>
                  {eight_component_footer}
                </FooterCapStyle>
              </Flex>
            </Flex>
            <Flex flexDirection="column" mt={10}>
              {Object.values(articles.articles_url).map((article, index) => {
                return (
                  <BlogArticles key={index} href={article.url}>
                    {article.name}
                  </BlogArticles>
                )
              })}
            </Flex>
            <Flex>
              {/*
              <FooterBottomitems>Español</FooterBottomitems>*/}
              <Box>
                <FooterBottomitems href={privacy_policy_slug}>
                  {privacy_policy_text}
                </FooterBottomitems>
              </Box>
              <Box ml={4}>
                <FooterBottomitems href={cookie_policy_slug}>
                  {cookie_policy_text}
                </FooterBottomitems>
              </Box>
              <Box ml={4}>
                <FooterBottomitems href={terms_slug}>
                  {terms_text}
                </FooterBottomitems>
              </Box>
            </Flex>
            <FooterBottomitems style={{ paddingTop: ' 10px' }}>
              {all_rights_reserved}
            </FooterBottomitems>
          </Flex>
        </MobileFlex>
      </Container>
    </FooterContainer>
  )
}

export default Footer
